<template>

      <v-container >
        <h2 >Product Details</h2>
        <p>See lists of users who can use <b> {{ arrays.product ? arrays.product.name : 'loading..' }} </b>, your subscription status, and the price of your subscription.</p>
   
         <v-snackbar v-model="snackbar.show" top right :color="snackbar.color">
          <v-icon class="mr-2">{{ snackbar.icon }}</v-icon>
          <span> {{ snackbar.text }} </span>
        </v-snackbar>

          <v-card  elevation="0" v-if="arrays.product">
            <v-img src="@/assets/product.svg" height="350" class="border rounded">
             <div class="align-bottom-left w-100">
              <div class="d-lg-flex d-md-block  justify-space-between align-center p-lg-3 p-sm-1">
               <div class="font-weight-bold mb-0  px-5 py-1">
                <small class="text-caption text-secondary">PRODUCT NAME </small>
                <h5 class="mt-0 black--text">{{ arrays.product.name }}</h5>
                <div v-if="arrays.me">
                  <small class="text-caption text-secondary">COMPANY</small>
                  <h5 class="mt-0 black--text">{{ arrays.me.default_user_company.company.name  }}</h5>
                </div>
               </div>
               <div class="text-dark font-weight-bold mb-0 px-5 py-1" >
                <div v-if="arrays.me">
                  <small class="text-caption text-secondary">SUBSCRIPTION STATUS </small>
                  <h5 class="mt-0 " v-if="arrays.subscribe">
                      <span class="black--text  text-uppercase" v-if="arrays.subscribe.expired"> Expired </span>
                      <span class="black--text  text-uppercase" v-if="!arrays.subscribe.expired"> Active </span>
                      <span class="black--text text-uppercase" v-if="status.trial"> For Trial </span>
                  </h5>
                  <h5 v-else>
                     Not Available
                  </h5>
                </div>
                <div v-if="arrays.me">
                  <small class="text-caption text-secondary">EXPIRY </small>
                    <h5 class="mt-0 black--text  text-uppercase " v-if="arrays.subscribe"> {{ arrays.subscribe.end_of_subscription | date }} </h5>
                    <h5 v-else>   Not Available  </h5>
                </div>
                <h5 v-if="!arrays.me"> 
                   <v-btn text color="#0A009B" width="150" @click="signIn">
                    Sign In
                  </v-btn>
                </h5>
               </div>
               <div class=" px-5 py-1">
                <small class="text-caption text-secondary">PRODUCT TYPE </small>
                <h5 class="mt-0 black--text  text-uppercase"> {{ arrays.product.type }} </h5>
               </div>
              </div>
             </div>
            </v-img>
             <p class="black--text my-4"> {{ arrays.product.description | productDescriptionMaxString }}</p>
             <div class="my-4" v-if="status.subs_plan">
               <h5> Subscription Plans </h5>
               <v-row v-if="arrays.plans && arrays.plans.length > 0" class="p-0 m-0" >
                 <v-col cols="12"
                    sm="12"
                    md="4"
                    lg="3"
                    xl="3"  v-for="(item,index) in arrays.plans" :key="index"  >
                   <v-card flat :color=" item.popular ? 'amber lighten-4' : '' "  > 
                     <div class="popular" v-if="item.popular">
                        POPULAR
                    </div>
                      <h4 class="pt-3 px-3 mb-0 text-center">
                           {{ item.plan }} 
                      </h4>
                     <v-card-text class="pt-1">
                        <p class="black--text my-4"> {{ item.description  }}</p>
                        <v-row align="center" align-content="middle" class="m-0 mb-2  black--text " v-for="(sub) in item.category" :key="sub"  >
                          <v-col lg="5" class="teal text-white rounded text-center" >
                              <p class="mb-0 text-capitalize"> {{ sub.periodical }}</p>
                          </v-col>
                          <v-col lg="7" class=" text-center">
                              <h5 class="mb-0" > {{ sub.amount | currency(true)}}  </h5>
                          </v-col>
                        </v-row>
                     </v-card-text>
                     
                   </v-card>
                 </v-col>
               </v-row>
             </div>
            <div v-else >
              <p class="text-caption text-uppercase">Product Pricing </p>
             <v-row dense>
              <v-col cols="12" lg="3" md="6" sm="6" xm="12" v-if="arrays.product.monthly_price > 0">
                 <p class="text-caption text-uppercase mb-0">Monthly</p>
                 <p class="h4 mb-0 mt-0 black--text font-weight-bold">{{ arrays.product.monthly_price | currency(true)}}</p>
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6" xm="12" v-if="arrays.product.quarterly_price > 0">
                 <p class="text-caption text-uppercase mb-0">Quarterly</p>
                 <p class="h4 mb-0 mt-0 black--text font-weight-bold">{{ arrays.product.quarterly_price | currency(true)}}</p>
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6" xm="12" v-if="arrays.product.semi_annual_price > 0">
                 <p class="text-caption text-uppercase mb-0">Semi Annual</p>
                 <p class="h4 mb-0 mt-0 black--text font-weight-bold">{{ arrays.product.semi_annual_price | currency(true)}}</p>
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6" xm="12" v-if="arrays.product.annually_price > 0">
                 <p class="text-caption text-uppercase mb-0">Annually</p>
                 <p class="h4 mb-0 mt-0 black--text font-weight-bold">{{ arrays.product.annually_price | currency(true)}}</p>
              </v-col>
             </v-row>
            </div>
     
              <div v-if="arrays.users && status.admin " id="usersTable" >
                  <h5> User List </h5>
                  <p class="black--text mb-0">Below is the list of users allowed to access <b>{{ arrays.product.name }} </b> for Company <b> {{ arrays.me.default_user_company.company.name  }} </b>. </p>
             
                  <div class="d-flex flex-wrap mt-4" v-if="!loadings.users">
                    <v-row class="mb-2">
                      <v-col cols="12" md="5" lg="5">
                        <v-text-field label="Search" v-model="search" prepend-icon="mdi-account-search-outline" hint="Search for Name" persistent-hint > </v-text-field>
                      </v-col>
                    </v-row>
                    <v-data-table 
                    class="w-100 border"
                      hide-default-footer
                      :headers="table.header"
                      :items="filterUsers"
                      :loading="loadings.table"
                      disable-sort >
                    <template v-slot:[`item.name`]="{ item }">
                      {{ item.user_company.user.first_name }} {{ item.user_company.user.last_name }} <b class="text-dark" v-if="item.user_company.user_id == arrays.me.id"> ( You ) </b>
                    </template>
                     <template v-slot:[`item.start`]="{ item }">
                       {{ item.start_of_subscription | displayDatev2 }}
                    </template>
                    <template v-slot:[`item.end`]="{ item }">
                       {{ item.end_of_subscription | displayDatev2 }}
                    </template>
                    <template v-slot:[`item.s_status`]="{ item }">
                        <span class="text-success mb-0" v-if="!item.expired && item.status == 'paid' || item.status == 'trial' ">Subscribed</span>
                        <span class="text-danger mb-0" v-else>Expired</span>

                    </template>
                    <template v-slot:[`item.a_status`]="{ item }">
                      <span class="text-success" v-if="item.user_company.status == 'active' "> Active </span>
                      <span v-else class="text-danger"> Inactive </span>
                    </template>
                    </v-data-table>
                    <div class="text-center w-100">
                        <Pagination v-bind:meta="pagination.users" @page="page"> </Pagination>
                    </div>
             
                  </div>
                  <div v-else class="py-5 text-center">
                    <v-progress-circular
                      indeterminate
                      size="80"
                    ></v-progress-circular>
                  </div>
               
              </div>
          
         
            <v-card-actions class="mt-4">
              <div class="w-100" v-if="arrays.me">
                <v-btn text  min-width="100" class="mr-3 text-secondary"  v-if="arrays.me" @click="visit" :loading="loadings.visit">VISIT PRODUCT</v-btn>
                <btnTrial v-bind:product="arrays.product" v-bind:myInfo="arrays.me" v-bind:invite="status.invited" v-bind:users="arrays.users" @transaction="trial" v-if="status.trial && status.admin"> </btnTrial>
                <v-btn text  min-width="100" color="#0A009B" @click="gotoSubscribe" v-if="arrays.subscribe && arrays.subscribe.expired && status.admin">SUBSCRIBE</v-btn>
                <v-btn text  min-width="100" color="#0A009B" @click="gotoSubscribe" v-if="arrays.subscribe && !arrays.subscribe.expired && status.admin">EXTEND</v-btn>
              </div>
              <div v-else class="w-100 ">
                <v-btn text color="#0A009B" @click="signUp">
                  Register
                </v-btn>
                <v-btn text color="#0A009B" @click="signIn">
                  Sign In
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
          <div v-else class="py-5 text-center" >
            <v-progress-circular
                indeterminate
                color="#00919B"
                size="150" >
            </v-progress-circular>
          </div>
  
      </v-container>
      
</template>
<script>
import btnTrial from '@/components/Product/Trial'
import Pagination from "@/components/Pagination";
export default {
  components: {
    btnTrial,
    Pagination
  },
  props: {
    myInfo : Object
  },
  data() {
    return {
      search : '',
      arrays: {
        product: null,
        me: null,
        users : null,
        subscribe : null,
        subs_plan : [],
        plans : []
      },
      loadings: {
        visit : true,
        users : true,
        table : true,
      },
      status :{
        admin: false,
        allUser : false,
        invited : false,
        trial : false,
        subs_plan : false,
      },
      snackbar: {
        show : false,
        color : 'primary',
        icon: 'mdi-check',
        text: ''
      },
      pagination : {
        users : null
      },
      table : {
        header : [
          {
            text : 'Name',
            value : 'name'
          },
          {
            text : 'Star of subscription',
            value : 'start'
          },
          {
            text : 'End of subscription',
            value : 'end'
          },
          {
            text : 'Subscription Status',
            value : 's_status',
            align: 'right',
            class : 'text-right'
          },
          {
            text : 'Account Status',
            value : 'a_status',
            align: 'right',
            class : 'text-right'
          },
        ]
      }
    }
  },
  computed : {
    filterUsers() {
      return this.arrays.users.filter((data) => {
        return (
          data.fullname.toLowerCase().match(this.search.toLowerCase() ) ||
          data.user_company.user.first_name.toLowerCase().match(this.search.toLowerCase() ) ||
          data.user_company.user.last_name.toLowerCase().match(this.search.toLowerCase() ) 
        );
      });
    },
  },
  watch: {
    myInfo: function()
    {
      this.getMe()
    }
  },
  created() {
    let product_id = this.$route.params.id
    this.getProduct(product_id)
    if(this.myInfo)
    {
      this.getMe()
    }
  },
  methods: {
    
    signIn()
    {
      window.location.href = this.$config.login;
    },
    signUp()
    {
      window.location.href = this.$config.login + 'sa?product='+this.arrays.product.id
    },
    getProduct(id)
    {
      this.axios.get('/products/'+id)
      .then( ({data}) => {
        this.arrays.product = data.products
        if(data.products.subs_plan && data.products.subs_plan.length > 0)
        {
          this.status.subs_plan = true
          this.arrays.subs_plan = data.products.subs_plan
          this.arrays.plans = this.getPlans()

        }
        this.prepareLink()
      })
    },
    getPlans()
    {
       var ar = [];
        if(this.arrays.subs_plan && this.arrays.subs_plan.length > 0)
        {
              for(var i in this.arrays.subs_plan)
              {
                  if(!isNaN(i))
                  {
                      var f = ar.filter( c =>  {
                          return c.plan == this.arrays.subs_plan[i].plan
                      })
                      
                      if(f.length < 1)
                      {
                          ar.push(this.arrays.subs_plan[i])
                      }
                  }
              } 
        }
      return this.getPeriods(ar)
     
    },
    getPeriods(arrays)
    {
      for(var i in arrays)
      {
        if(!isNaN(i))
        {
         arrays[i].category = this.getCategory( arrays[i] )
        }
      }
      return arrays
    },
    getCategory(item)
    {
      var ar = []
      if(this.arrays.subs_plan && this.arrays.subs_plan.length > 0)
      {
        for(var i in this.arrays.subs_plan)
        {
          if(!isNaN(i))
          {
            if(this.arrays.subs_plan[i].plan == item.plan)
            {
              ar.push(this.arrays.subs_plan[i])
            }
          }
        }
      }
     
      return ar
    },
    getMe()
    {
      this.arrays.me = this.myInfo
      if(this.arrays.me.default_user_company.role == 'SA')
      {
        this.status.admin = true
      }
      this.getCompanyUsers()
    },
    expirationChecker(users)
    {
      let arrange = []

      if(users && users.length > 0)
      {

        for(let i in users)
        {
          if(!isNaN(i))
          {
            if(users[i].user_company_id == this.arrays.me.current_user_company_id)
            {
              users[i].expired = this.$options.filters.subscriptionChecker(users[i].end_of_subscription)
              arrange.push(users[i])
            }
          }
        }
        for(let i in users)
        {
          if(!isNaN(i))
          {
            if(users[i].user_company_id != this.arrays.me.current_user_company_id)
            {
              users[i].expired = this.$options.filters.subscriptionChecker(users[i].end_of_subscription)
              arrange.push(users[i])
            }
          }
        }
        for(let i in users)
        {
          if(!isNaN(i))
          {
            users[i].fullname = this.$options.filters.fullname(users[i].user_company.user.first_name, users[i].user_company.user.last_name )
          }
        }
      }
      return arrange
    },
    getCompanyUsers(page)
    {
      let p = ''
      if(page)
      {
        p = page
        this.loadings.table = true
      }else{
         this.loadings.users = true
      }
       
        let company_id = this.arrays.me.default_user_company.company.id

        this.axios.get("/subscriptions?company_id=" + company_id + '&latest_expiration=true&page='+p+'&products_id='+this.$route.params.id)
        .then( ({data}) => {
          this.arrays.users = this.expirationChecker( data.subscription )
          this.loadings.users = false
          if(!page)
          {
            this.arrays.users.total = data.meta.total
           
          }
           this.pagination.users = data.meta
          if(page)
          {
            if(window.innerWidth < 600)
            document.getElementById('usersTable').scrollIntoView()
          }
          this.loadings.table = false
          this.getProductSubscription()
          this.checkInvitation()
          this.prepareLink()
        })
    },
    prepareLink()
    {
      if(this.arrays.product && this.arrays.me)
      {
        let user_company_id = this.arrays.me.default_user_company.id
        let company_id = this.arrays.me.default_user_company.company.id
        this.arrays.product.link = this.$options.filters.productLink(this.arrays.product.name, this.arrays.product.url, user_company_id, company_id)
        this.loadings.visit = false
      }
      
    },
    getProductSubscription()
    {
      let p_id = this.$route.params.id
      let u_c_id = this.arrays.me.default_user_company.id
      this.axios.get('/subscriptions?products_id='+p_id+'&subscription_range=true&user_company_id='+u_c_id)
      .then( ({data}) => {
        this.arrays.subscribe = data.subscription[0]
        this.prepareLink()
        if(this.arrays.subscribe)
        {
          let expired = this.$options.filters.checkEndOfSubscription(this.arrays.subscribe.end_of_subscription)

          if(expired)
          {
            this.arrays.subscribe.expired = true
          }else{
            this.arrays.subscribe.expired = false
          }
          
        }else{
          this.status.trial = true
        }
      })
    },
    checkInvitation()
    {
      this.axios.get('/inviter?user_id='+this.arrays.me.id)
      .then(({data}) => {
        data.inviter.length > 0 ? this.status.invited = true : this.status.invited = false
      })
    },
    visit()
    {
      window.open(this.arrays.product.link)
    },
    gotoSubscribe()
    {
      this.$router.push({path: '/account/subscribeproduct/'+this.arrays.product.id})
    },
    trial(status)
    {
 
      if(status)
      {
        console.log('trigger this', status )
        this.snackbar.text = 'Transaction Success!'
        this.snackbar.color = 'success'
        this.snackbar.icon = 'mdi-check'
         this.status.trial = false
        this.getCompanyUsers()
      }else{
        this.snackbar.text = 'Failed to Subcribe for Trial'
        this.snackbar.icon = 'mdi-close'
        this.snackbar.color = 'error'
      }
      this.snackbar.show = true
    },
    page(page)
    {
      this.getCompanyUsers(page)
    }
  },
};
</script>


<style type="text/css" scoped>
.align-bottom-left{
 position: absolute;
 bottom: 0;
 left: 0;
}
.border-bottom-yellow--2{
 border-bottom: 2px solid #fff426;
}
.text-money{
 color: #ffa600;
 font-weight: bold;
}
button:active,
button:focus{
 outline: none;
}
.border-right-black{
  border-right: 1px solid black;
}
.border-red {
  border: 1px solid #dc3545!important;
}
.border-success{
  border: 1px solid #4dff76!important;
}
.text-active{
  color: #4dff76!important;
}
.v-data-table-header-mobile{
    display: none!important;
  }
</style>