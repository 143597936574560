<template>
  <v-dialog
      v-model="dialog"
      width="500"
      persistent >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text  min-width="100"  color="#0A009B"
          @click="dialog = true"
          v-bind="attrs"
          v-on="on" >
         START TRIAL
        </v-btn>
      </template>
      <v-card>
        <v-toolbar flat color="#00439B" class="border-bottom">
          <v-toolbar-title class="text-white">Notification</v-toolbar-title>
        </v-toolbar>
        <div class="p-3">
          Your trial for Product : <b> {{ arrays.product.name }} </b>will start now and will expire by <b> {{ display.end | displayDatev2 }}</b>
        </div>
        <v-card-actions class="border-top">
          <v-spacer></v-spacer>
          <v-btn class="text-secondary" text @click="dialog = false"> Cancel </v-btn>
          <v-btn
            color="#0A009B"
            text
            :loading="loadings.me"
            @click="trial" >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>

export default {
 props: ['product', 'myInfo', 'users', 'invited'],
 data() {
  return  {
   arrays: {
    product: null,
    me: null,
    users_in_company: null,
   },
   form: {
    transaction_date : this.$options.filters.today(),
    payment_amount: 0,
    total_amount : 0,
    hasSubscription : null,
   },
   status : {
    invited : false
   },
   display : {
     start : null,
     end : null
   },
   loadings : {
     me : true
   },
   dialog : false
  }
 },
 watch: {
  product: function(data)
  {
   if(data)
   {
    this.arrays.product = data
   }
  },
  myInfo: function(data)
  {
   if(data)
   {
    this.arrays.me = data
    this.calculate()
    this.loadings.me = false
   }
  },
  users: function(data)
  {
   if(data)
   {

    this.arrays.users_in_company = data
   }
  },
  invited: function(boolean)
  {
   if(boolean)
   {
    this.status.invited = true
   }
  }
 },
 created()
 {
  if(this.myInfo)
  {
   this.arrays.me = this.myInfo
    this.calculate()
   this.loadings.me = false
  }
  if(this.product)
  {
   this.arrays.product = this.product
  }
  if(this.invited)
  {
   this.status.invited = true
  }
 },
 methods: {
  calculate()
  {
    if(this.arrays.me)
    {
      this.display.start = this.$options.filters.userStartofSubscriptionv2( this.arrays.me.default_user_company.company.billing_date )
      this.display.end = this.$options.filters.trialDay( 30 )
    }
  },
  trial()
  {
    this.loadings.me = true
   this.form.user_id = this.arrays.me.id
   this.form.product_id = this.arrays.product.id
   this.form.wallet_id = this.arrays.me.id
   if(this.arrays.product.subs_plan && this.arrays.product.subs_plan.length > 0 && this.arrays.product.type == 'storage' ) {
     this.form.forStorage = this.arrangeUser()
     this.form.product_type = 'storage'
     this.form.company_id = this.arrays.me.default_user_company.company_id
     this.$delete(this.form, 'hasSubscription')
   }else{
     this.form.hasSubscription = this.arrangeUser()
   }
   

   this.axios.post('/accounting',this.form)
   .then(() => {
     this.loadings.me = false
     this.$emit('transaction', this.arrays.product)
     this.dialog = false
   })
   .catch( () =>{
    this.loadings.me = false
    this.$emit('transaction', false)
   })
  },
  arrangeUser()
  {
   let product = this.arrays.product
   let arrange = [];
   let addDays = 30 
   let billing_date = this.arrays.me.default_user_company.company.billing_date
   if (this.arrays.users_in_company && this.arrays.users_in_company.length > 0)
   {
     for (let u in this.arrays.users_in_company) {
       if (!isNaN(u)) {
         arrange[u] = {
           products_id: product.id,
           subs_plan_id : null,
           start_of_subscription: this.$options.filters.apiDate(),
           end_of_subscription: this.$options.filters.trialDay(addDays),
           user_company_id: this.arrays.users_in_company[u].user_company.id,
           subscription_amount: 0,
           transaction_id: 0,
           status: "trial",
         };
       }
     }
     return arrange;
   }
  else{
   let start = this.$options.filters.userStartofSubscriptionv2( billing_date )
   let end = this.$options.filters.trialDay(addDays)
   let u_id = this.arrays.me.default_user_company.id

    let data = [{
      id : null,
      products_id: product.id,
      start_of_subscription: start,
      end_of_subscription: end,
      user_company_id: u_id,
      subscription_amount: 0,
      transaction_id: 0,
      status: "trial",
      subs_plan_id : null,
    }]
    return data
   }
  }
 }
}
</script>